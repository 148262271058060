import React from "react";
import styles from "./features.module.css";
import features from "../../assets/icons/features.webp";
import apps from "../../assets/icons/apps.webp";
import frontend from "../../assets/icons/frontend.webp";
import cloud from "../../assets/icons/cloud.webp";
import chat from "../../assets/icons/chat.webp";
import admin from "../../assets/icons/admin.webp";
export function Features() {
  return (
    <div className={styles.container}>
      <h2 className={styles.mainHeader}>
        FEATURES, DIE EURE AGENTENHERZEN HÖHER SCHLAGEN LASSEN WERDEN!
      </h2>
      <div className={styles.mainSection}>
        <div className={styles.imageSection}>
          <img className={styles.imgFeatures} src={features} alt={"features"} />
        </div>
        <div className={styles.descriptionSection}>
          <ul className={styles.list}>
            <li className={styles.listItem}>
              <div className={styles.listItemIconSection}>
                <img
                  className={styles.listItemIcon}
                  src={apps}
                  alt={"apps icon"}
                />
              </div>
              <p className={styles.listItemDescription}>
                Speziell entwickelte Apps, die euren Benutzern ein
                unvergleichliches Erlebnis bieten. Verbindet euch über
                Push-Benachrichtigungen direkt mit euren Kunden – jederzeit und
                überall.
              </p>
            </li>
            <li className={styles.listItem}>
              <div className={styles.listItemIconSection}>
                <img
                  className={styles.listItemIcon}
                  src={frontend}
                  alt={"frontend icon"}
                />
              </div>
              <p className={styles.listItemDescription}>
                Egal ob am Schreibtisch oder unterwegs, unser Web-Frontend passt
                sich an eure Bedürfnisse an und bietet eine nahtlose
                Nutzererfahrung.
              </p>
            </li>
            <li className={styles.listItem}>
              <div className={styles.listItemIconSection}>
                <img
                  className={styles.listItemIcon}
                  src={cloud}
                  alt={"cloud icon"}
                />
              </div>
              <p className={styles.listItemDescription}>
                Eure "Agentenzentrale" mit E-Mail, Dateiverwaltung,
                Videoanrufen, Handbüchern und mehr. Organisation war noch nie so
                einfach und sicher!
              </p>
            </li>
            <li className={styles.listItem}>
              <div className={styles.listItemIconSection}>
                <img
                  className={styles.listItemIcon}
                  src={chat}
                  alt={"chat icon"}
                />
              </div>
              <p className={styles.listItemDescription}>
                Gestaltet euren Chat, wie es euch gefällt! Effizienz und
                Kontrolle sind in eurer Hand.
              </p>
            </li>
            <li className={styles.listItem}>
              <div className={styles.listItemIconSection}>
                <img
                  className={styles.listItemIcon}
                  src={admin}
                  alt={"admin icon"}
                />
              </div>
              <p className={styles.listItemDescription}>
                Behaltet den Überblick über Live-Umsätze, Traffic, Kunden und
                Mitarbeiter. Eure Agentur wird floriert, und ihr habt die Zügel
                in der Hand.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
