import React from "react";
import styles from "./welcome.module.css";
import { Converse } from "../Converse";

export function Welcome() {
  return (
    <div className={styles.container}>
      <div className={styles.headerSection}>
        <h2 className={styles.mainHeader}>WILLKOMMEN BEI {<Converse />}</h2>
        <h4 className={styles.subHeader}>
          DEINER PLATTFORM FÜR DIE PERFEKTE CHAT-BUSINESS EVOLUTION!
        </h4>
      </div>
      <p className={styles.description}>
        <span>
          Agenten mit Leidenschaft für Chat-Plattformen, hört her!
          <span className={styles.agent}>&nbsp;</span>
          <span>&nbsp;</span>
          <span className={styles.agent}>&nbsp;</span>
        </span>
      </p>
      <p className={styles.description}>
        Ihr seid die wahren Matchmaker im digitalen Zeitalter und habt bereits
        die Magie der Verbindung auf Dating-Plattformen erlebt. Jetzt ist es an
        der Zeit, diese Magie selbst zu erschaffen, indem ihr eure eigene
        Chat-Plattform mit {<Converse />} aufbaut!
      </p>
    </div>
  );
}
