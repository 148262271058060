import React, { useEffect, useRef, useState } from "react";
import styles from "./hero.module.css";

import { Converse } from "../Converse";
import logo from "../../assets/images/full_logo.webp";
import swiperImage1 from "../../assets/swiper/s1.webp";
import swiperImage2 from "../../assets/swiper/s2.webp";
import swiperImage3 from "../../assets/swiper/s3.webp";
import swiperImage4 from "../../assets/swiper/s4.webp";
import swiperImage5 from "../../assets/swiper/s5.webp";
import swiperImage6 from "../../assets/swiper/s6.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
//import { useSwiper } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Controller } from "swiper/modules";
export function Hero() {
  const Slide1 = () => {
    return (
      <div className={styles.swiper__slide}>
        <div className={styles.swiper__slide_imgContainer}>
          <img
            className={styles.swiper__slide_img}
            src={swiperImage1}
            aria-hidden={true}
            alt={"product image"}
          />
        </div>
        <div className={styles.swiper__slide_description}>
          <h2 className={styles.swiper__slide_header}>
            GIB DEINEM PRODUKT EIN GESICHT!
          </h2>
          <p className={styles.swiper__slide_text}>
            Mit {<Converse />} bekommst Du dein eigenes Dating-Portal, welches
            deinem Stil & Geschmack entspricht.{" "}
          </p>
          <p className={styles.swiper__slide_text}>
            Wir haben nicht nur diverse vorhandene Layouts, sondern erstellen
            dir auf Wunsch auch dein ganz persoenliches Wunschdesign inklusive
            Logo.
          </p>
        </div>
      </div>
    );
  };
  const Slide2 = () => {
    return (
      <div className={styles.swiper__slide}>
        <div className={styles.swiper__slide_imgContainer}>
          <img
            className={styles.swiper__slide_img}
            src={swiperImage2}
            aria-hidden={true}
            alt={"product image"}
          />
        </div>
        <div className={styles.swiper__slide_description}>
          <h2 className={styles.swiper__slide_header}>
            FLEXIEBLE ANPASSBARKEIT
          </h2>
          <p className={styles.swiper__slide_text}>
            Mit {<Converse />} profitierst Du von maßgeschneiderter Anpassung in
            der App und Web-Oberfläche. Die Plattform bietet Flexibilität für
            Branding, Design und Funktionen, sodass Du eine einzigartige
            Chat-Erfahrung kreieren kannst. Mit {<Converse />} setzt Du Deine
            individuellen Vorstellungen um.
          </p>
        </div>
      </div>
    );
  };

  const Slide3 = () => {
    return (
      <div className={styles.swiper__slide}>
        <div className={styles.swiper__slide_imgContainer}>
          <img
            className={styles.swiper__slide_img}
            src={swiperImage3}
            aria-hidden={true}
            alt={"product image"}
          />
        </div>
        <div className={styles.swiper__slide_description}>
          <h2 className={styles.swiper__slide_header}>
            FERTIGE ANDROID & IOS LÖSUNG
          </h2>
          <p className={styles.swiper__slide_text}>
            Mit {<Converse />} bekommst Du optimierte Lösungen für Android und
            iOS. Die Plattform wurde speziell entwickelt, um auf beiden
            Betriebssystemen nahtlos zu funktionieren, sodass Nutzer ein
            konsistentes Chat-Erlebnis genießen können. Egal ob auf einem
            Android-Smartphone oder einem iPhone, mit {<Converse />} ist
            Qualität und Performance garantiert.
          </p>
        </div>
      </div>
    );
  };

  const Slide4 = () => {
    return (
      <div className={styles.swiper__slide}>
        <div className={styles.swiper__slide_imgContainer}>
          <img
            className={styles.swiper__slide_img}
            src={swiperImage4}
            aria-hidden={true}
            alt={"product image"}
          />
        </div>
        <div className={styles.swiper__slide_description}>
          <h2 className={styles.swiper__slide_header}>
            MAXIMALE DATENSICHERHEIT
          </h2>
          <p className={styles.swiper__slide_text}>
            Mit {<Converse />} steht Datensicherheit an erster Stelle. Die
            Plattform verwendet fortschrittliche Verschlüsselungstechniken, um
            sicherzustellen, dass alle Kommunikationen und Nutzerdaten stets
            geschützt sind. Egal ob Chats, Dateien oder persönliche
            Informationen, mit {<Converse />} kannst Du dich auf einen
            vertrauenswürdigen und sicheren Dienst verlassen.
          </p>
        </div>
      </div>
    );
  };

  const Slide5 = () => {
    return (
      <div className={styles.swiper__slide}>
        <div className={styles.swiper__slide_imgContainer}>
          <img
            className={styles.swiper__slide_img}
            src={swiperImage5}
            aria-hidden={true}
            alt={"product image"}
          />
        </div>
        <div className={styles.swiper__slide_description}>
          <h2 className={styles.swiper__slide_header}>
            GRENZENLOSE SKALIERBARKEIT
          </h2>
          <p className={styles.swiper__slide_text}>
            Mit {<Converse />} erlebst Du grenzenlose Skalierbarkeit. Die
            Plattform ist so konzipiert, dass sie mit Deinem Wachstum Schritt
            hält, von wenigen Nutzern bis zu Millionen. Unabhängig von der Größe
            Deines Unternehmens garantiert {<Converse />} stets eine
            reibungslose und leistungsstarke Performance.
          </p>
        </div>
      </div>
    );
  };

  const Slide6 = () => {
    return (
      <div className={styles.swiper__slide}>
        <div className={styles.swiper__slide_imgContainer}>
          <img
            className={styles.swiper__slide_img}
            src={swiperImage6}
            aria-hidden={true}
            alt={"product image"}
          />
        </div>
        <div className={styles.swiper__slide_description}>
          <h2 className={styles.swiper__slide_header}>
            VOLLWERTIGES PAYMENT GATEWAY
          </h2>
          <p className={styles.swiper__slide_text}>
            Mit {<Converse />} bekommst Du ein sofort einsatzbereites Payment
            Gateway. Dieses integrierte Zahlungssystem ermöglicht sichere
            Transaktionen ohne komplexe Einrichtung. Egal ob Einmalzahlungen
            oder Abonnements, mit {<Converse />} verarbeitest Du Deine Zahlungen
            nahtlos und zuverlässig.
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.header__content}>
        <div className={styles.swiper}>
          <Swiper
            modules={[Pagination, Autoplay, Controller]}
            spaceBetween={50}
            slidesPerView={1}
            observer={true}
            onResize={(swiper) => swiper.update()}
            observeParents={true}
            updateOnWindowResize={true}
            navigation
            autoplay={{ delay: 3000 }}
            loop={true}
            pagination={{ clickable: true }}
          >
            <SwiperSlide>
              <Slide1 />
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <Slide2 />
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <Slide3 />
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <Slide4 />
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <Slide5 />
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <Slide6 />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className={styles.logo}>
          <img className={styles.logo_img} src={logo} alt={"company logo"} />
        </div>
      </div>
    </div>
  );
}
